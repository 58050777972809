// APP_ROLES are defined in the auth0 user's app_metadata.  An auth0 account can be an admin in the embedops platform,
// by setting app_metadata={"appRole": "admin"}.  This enum expresses the two options so FE code can smartly
// provide access control

export enum APP_ROLE {
  admin = 'admin',
  user = 'user',
}

export type BaseEntity = {
  createdAt: string; // TODO: this is a string
  updatedAt: string;
  id: string;
};

export enum Permission {
  read = 'read',
  update = 'update',
  admin = 'admin',
}

export type Org = BaseEntity & {
  name: string;
  parentId?: Org['id'];
  projects: Project[];
  tierText: SubscriptionTier;
};

export type OrgWithTier = Org & { tierText: string };

export type Project = BaseEntity & {
  name: string;
  orgId: Org['id'];
  // list of repos is included in the GET /projects/{id}
  repos?: Repo[];
  // count of repos is included in GET /projects
  _count?: {
    repos?: number;
  };
};

export enum CI_PROVIDERS {
  gitlab = 'gitlab',
  github = 'github',
  bitbucket = 'bitbucket',
}

export type CiOAuthToken = BaseEntity;

export enum REPO_TYPE {
  code = 'code',
  hil = 'hil',
}

export type Repo = BaseEntity & {
  name: string;
  projectId: Project['id'];
  defaultBranch: string;
  desc?: string;
  // only used for tests
  project?: Partial<Project>;
  uri: string;
  ciProvider?: CI_PROVIDERS;
  ciOAuthTokenId?: CiOAuthToken['id'];
  type: REPO_TYPE;
};

export type RepoApiKey = BaseEntity & {
  active: boolean;
  key: string;
  repoId: Repo['id'];
};

export type OrgApiKey = BaseEntity & {
  active: boolean;
  key: string;
  orgId: Org['id'];
};

export type Fleet = BaseEntity & {
  fleetId: string;
  repoId: Repo['id'];
};

export type FleetDevice = BaseEntity & {
  fleedId: string;
  deviceId: string;
  publicKey: string;
  privateKey?: string;
};

export type FleetDeviceInfo = {
  id: string;
  device_name: string;
  is_online: boolean;
  last_connectivity_event: string;
  release: string;
  appDefinedStatus: 'offline' | 'provisioning' | 'updating' | 'available' | 'unknown';
  updatingProgress?: number;
};

export type FleetWithDetail = Fleet & {
  devices: FleetDeviceInfo[];
};

export enum STAGE {
  TEST = 'Test',
  QUALITY = 'Quality',
  BUILD = 'Build',
}

type PipelineBaseJob = {
  id: string;
  jobDefId: string;
  name: string;
  stage: STAGE;
};

export type PipelineTestJob = PipelineBaseJob & {
  stage: STAGE.TEST;
};

export type PipelineQualityJob = PipelineBaseJob & {
  stage: STAGE.QUALITY;
};

export type PipelineBuildJob = PipelineBaseJob & {
  stage: STAGE.BUILD;
  parameters: {
    buildCommand: string;
  };
};

export type RepoPipelineJob = PipelineBuildJob | PipelineTestJob | PipelineQualityJob;

export type RepoPipelineConfig = BaseEntity & {
  jobs: RepoPipelineJob[];
  repoId: Repo['id'];
};

export enum USER_INVITE_STATUS {
  accepted = 'accepted',
  invited = 'invited',
}

export enum SubscriptionTier {
  Free = 'Free',
  Pro = 'Pro',
  Enterprise = 'Enterprise',
}

export type User = BaseEntity & {
  auth0Id: string;
  email: string;
  familyName?: string;
  givenName?: string;
  inviteStatus?: USER_INVITE_STATUS;
  inviteTicket?: string;
  name?: string;
  nickname?: string;
  onboardingDismissed: boolean;

  groups?: UserToGroup[];
  ownedSubscriptions: { id: string; orgId: Org['id'] }[];
};

export type UserCiOauthTokens = {
  [ciProvider in CI_PROVIDERS]?: CiOAuthToken['id'];
};

export type UserToGroup = {
  group: Pick<Group, 'orgId' | 'orgPerm'>;
  groupId: Group['id'];
  userId: User['id'];
  userPerm: Permission;
};

export type Group = BaseEntity & {
  name: string;
  orgId: Org['id'];
  orgPerm: Permission;
  projects: GroupToProject[];
  users: GroupToUser[];
};

export type GroupToUser = {
  user: Pick<User, 'id' | 'email' | 'inviteTicket' | 'inviteStatus'>;
  userPerm: Permission;
};

export type GroupToProject = {
  projectPerm: Permission;
  project: Pick<Project, 'name' | 'id'>;
};

export type CiRunType = 'quality' | 'test' | 'build';

export enum CiRunStatus {
  failure = 'failure',
  running = 'running',
  success = 'success',
  canceled = 'canceled',
}

export type CiRunSourceType = 'branch' | 'tag';

export type CiRun = {
  id: string;
  branch: string;
  sourceType: CiRunSourceType;
  commitId: string;
  name: string;
  pipelineId: string | null;
  pipelineUrl: string | null;
  repo: Repo;
  repoId: Repo['id'];
  status: CiRunStatus;
  type: string;
  createdAt: string;
  updatedAt: string;
  commitMessage: string;
};

export type ReleaseFile = {
  id: string;
  url: string;
  releaseId: string;
  createdAt: string;
  updatedAt: string;
};

export type Release = {
  id: string;
  name: string;
  commitId: string;
  projectId: string;
  ciRunId: string;
  createdAt: string;
  updatedAt: string;
  ciRun: Pick<CiRun, 'repoId'>;
};

export type Metric = BaseEntity & {
  dimensions: Record<string, string>;
  data: { binary_records?: any[]; events?: any[] };
  name: string;
  // it's a string, but should be a number?
  // Could this be because the DECIMAL column type was used in the database?
  value: string;

  ciRun: Pick<
    CiRun,
    | 'id'
    | 'branch'
    | 'commitId'
    | 'commitMessage'
    | 'name'
    | 'pipelineId'
    | 'pipelineUrl'
    | 'status'
    | 'createdAt'
    | 'updatedAt'
  > & {
    repo: Pick<Repo, 'id' | 'name'>;
  };
};

export type SymbolDataPoint = {
  ciRun: Pick<CiRun, 'id' | 'createdAt' | 'branch' | 'commitId' | 'commitMessage'>;
  size: number;
};

export type CiRunWithMetric = CiRun & {
  Metric: Metric[];
};

export type CiRunWithMetricAndHilRun = CiRun & {
  Metric: Metric[];
  HilRun: Pick<HILRun, 'id' | 'failCount'>[];
};

export type RepoBranch = Pick<
  CiRun,
  'branch' | 'sourceType' | 'commitId' | 'createdAt' | 'repoId' | 'status' | 'type' | 'pipelineUrl'
> & {
  type: CiRunType;
};

export enum METRIC_NAMES {
  flash = 'flash_size',
  ram = 'ram_size',
  binary_report = 'binary_report',
}

export enum DIM {
  build_target = 'build_target',
  commitId = 'commitId',
}

export enum TESTING_METRIC_NAMES {
  passing_tests = 'passing_tests',
  failed_tests = 'failed_tests',
  total_tests = 'total_tests',
  runtime = 'runtime',
}

export type JobTemplate = BaseEntity & {
  jobDefId: JobDef['id'];
  name: string;
  provider: CI_PROVIDERS;
  stage: STAGE;
  template: string;
};

// A job definition is a type of job that can be added to a RepoCiPipeline.
// It defines the name, stage, default settings and overrideable parameters.
// Instances of a JobDef (for a particular repo's ci pipeline) refer back
// to the id of the JobDef
export type BaseJobDef = {
  name: string;
  id: string;
  stage: STAGE;
  // subStage is used by "Quality" to show <optgroups>
  substage?: string;
  //parameters: {};
  JobTemplate?: JobTemplate[];
};

export type TestJobDef = BaseJobDef & {
  stage: STAGE.TEST;
};

//  A "Quality" job is in stage "Qualy" and only has a "sourceDirectory" parameter
export type QualityJobDef = BaseJobDef & {
  stage: STAGE.QUALITY;
  // parameters: {
  //   sourceDirectory: 'string'
  // }
};

// A Build job is in stage "Build" and has a set of overrideable parameters that customize the
// build tool.  TBD: how to identify the docer image based on these parameters.
//  A build job stores the build output as an artifact in the CI system
export type BuildJobDef = BaseJobDef & {
  stage: STAGE.BUILD;
  //parameters: {
  //  platform: string;
  //  buildTarget: TARGET;
  //  buildSystem: string;
  //  buildCommand: string;
  //};
};

export type JobDef = TestJobDef | QualityJobDef | BuildJobDef;
export enum DATE_FILTERS {
  last_30_days = 'last_30_days',
  last_90_days = 'last_90_days',
  last_6_months = 'last_6_months',
  last_year = 'last_year',
}

export type HILRun = {
  id: string;
  createdAt: string;
  updatedAt: string;
  ciRunId: string;
  status: string;
  deviceName: string;
  passCount: number;
  failCount: number;
  executionTime: number;
};

export type HilRunLogItem = {
  id: string;
  createdAt: string;
  updatedAt: string;
  hilRunId: string;
  value: string;
  level: string;
};

export type HILCIRun = {
  id: string;
  createdAt: string;
  updatedAt: string;
  ciRunId: string;
  logItems: HilRunLogItem[];
  status: string;
  deviceName: string;
  deviceRelease: string;
  passCount: number;
  failCount: number;
  executionTime: number;
  ciRun: CiRun;
};

export type HILCIRunsQuery = {
  total: number;
  results: HILCIRun[];
};

export type HilTestLogItem = {
  id: string;
  createdAt: string;
  updatedAt: string;
  hilRunTestId: string;
  value: string;
  level: string;
};

export type HilArtifact = {
  id: string;
  name: string;
  size: string;
};

export type HilRunTest = {
  id: string;
  createdAt: string;
  updatedAt: string;
  hilRunId: string;
  execTime: number;
  name: string;
  status: string;
  logItems: HilRunLogItem[];
  artifacts: HilArtifact[];
};

export type HILCIRunQuery = {
  id: string;
  createdAt: string;
  updatedAt: string;
  ciRunId: string;
  logItems: HilRunLogItem[];
  status: string;
  deviceName: string;
  deviceRelease: string;
  passCount: number;
  failCount: number;
  executionTime: number;
  ciRun: CiRun;
  HilRunTest: HilRunTest[];
};

export type ActiveUsersData = {
  dailyAmount: Record<string, number>;
  distinctUsersAmount: number;
};

export type Template = BaseEntity & {
  orgId: Org['id'];
  uri: string;
  hasAccessToken: boolean;
};
